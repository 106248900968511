<template>
  <div>
    <div class="main-title">合作校区</div>
    <div class="content-list-page page-content">
      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l margin-l-sm">
          <el-input
            v-model="searchData.name"
            clearable
            placeholder="校区名称"
            size="mini"
          ></el-input>
        </span>
        <span class="fl-l margin-l-sm">
          <el-input
            v-model="searchData.contact_name"
            clearable
            placeholder="联系人"
            size="mini"
          ></el-input>
        </span>

        <span class="fl-l margin-l-sm">
          <el-input
            v-model="searchData.contact_mobile"
            clearable
            placeholder="联系手机"
            size="mini"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l"> </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link class="router-link" to="/campus/add">
            <el-button size="small" type="primary" v-if="role === 1">新增</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column align="center" label="ID" property="id" width="80" />
          <el-table-column align="center" label="校区名称" property="name" />
          <el-table-column
            align="center"
            label="联系人"
            property="contact_name"
          />
          <el-table-column
            align="center"
            label="联系手机"
            property="contact_mobile"
          />
          <el-table-column align="center" label="合作课程">
            <template slot-scope="scope">
              <el-link @click="getCourse(scope.row)">查看课程</el-link>
            </template>
          </el-table-column>
          <el-table-column align="center" label="总金额">
            <template slot-scope="scope"
              >{{ scope.row.total_amount }}¥
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="总人数"
            property="total_nums"
          />
          <el-table-column
            align="center"
            label="已加入人数"
            property="joined_nums"
          />
          <el-table-column align="center" label="创建人" property="user_name" />
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          />

          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <span class="top" v-if="role === 1">
                <el-link type="primary" @click="edit(scope.row)"
                  >编辑</el-link
                > </span
              >
              <span v-if="role === 1">
                -
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row.id)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
              <span v-if="scope.row.joined_nums !== scope.row.total_nums"> 
                <span v-if="role == 1"> - </span>
                <el-popover placement="right" trigger="click" width="200">
                  <el-image v-loading="codeLoading" :src="baseUrl + '/' + code" />
                  <el-link
                    slot="reference"
                    type="primary"
                    @click="getCode(scope.row.id)"
                    >生成二维码
                  </el-link>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="合作课程"
      width="60%"
    >
      <div class="flex tables" style="width: 100%">
        <div style="width: 55%; border-right: 1px solid #eee">
          <h1>打卡课程</h1>
          <el-table :data="courses.check_course_list" style="width: 100%">
            <el-table-column label="课程名称" prop="name"></el-table-column>
            <el-table-column label="封面">
              <template slot-scope="scope">
                <el-image
                  :src="imageUrl + scope.row.pic"
                  style="width: 120px"
                />
              </template>
            </el-table-column>
            <el-table-column label="开始天数">
              <template slot-scope="scope">
                <span>{{
                  scope.row.is_all === 1 ? "无" : scope.row.start_plan_day
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束天数">
              <template slot-scope="scope">
                <span>{{
                  scope.row.is_all === 1 ? "无" : scope.row.end_plan_day
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始时间" prop="start_at" />
            <el-table-column label="更新周期">
              <template slot-scope="scope">
                <span v-for="item in scope.row.update_day" :key="item">
                  {{ item }},
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 40%; border-left: 1px solid #eee">
          <h1 style="text-indent: 1em">课程</h1>
          <el-table :data="courses.course_list" style="width: 100%">
            <el-table-column label="课程名称" prop="name"></el-table-column>
            <el-table-column label="封面">
              <template slot-scope="scope">
                <el-image
                  :src="imageUrl + scope.row.pic"
                  style="width: 120px"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { config } from "../../../js/config";
import { mapActions } from "vuex";

export default {
  name: "list",
  data() {
    return {
      dialogVisible: false,
      page: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      imageUrl: config.imageUrl,
      baseUrl: config.baseUrl,
      searchData: {},
      tableData: [],
      codeLoading: false,
      code: "",
      courses: {
        course_list: [],
        check_course_list: [],
      },
      role: this.$store.state.user.info.role,
    };
  },
  methods: {
    ...mapActions("collaborate", ["schoolList", "delSchool", "getWxacode"]),
    edit(row) {
      this.$router.push("/campus/edit/" + row.id);
    },
    getCourse(row) {
      this.dialogVisible = true;
      this.courses = row.courses;
    },
    async deleted(id, rowIndex) {
      try {
        const { res_info } = await this.delSchool(id);
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
        await this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    async getCode(id) {
      try {
        this.codeLoading = true;
        let form = {
          id,
          page: "packageProduct/collaborate/index",
          size: 200,
        };
        const { data } = await this.getWxacode(form);
        this.code = data;
      } finally {
        this.codeLoading = false;
      }
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        const { data } = await this.schoolList(this.searchData);
        this.tableData = data.list;
        this.total = data.total;
      } finally {
        this.loading = false;
      }
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },

    changeLevelAndGrade(e) {
      this.selectLevelAndGrade = e;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.el-table .cell span.el-tag {
  margin-right: 5px;
  display: inline-block;
}

.flex {
  display: flex;
  justify-content: space-between;
}
</style>